module.exports = {
  messages: {
    COACHES_SUNSET_COACHING_CARD_BUTTON: 'Browse now',
    COACHES_SUNSET_COACHING_CARD_DESCRIPTION:
      'Browse our list of coaches and find someone offering precisely the kind of coaching you’re looking for.',
    COACHES_SUNSET_COACHING_CARD_HEADLINE: 'Looking for a coach?',
    COACHES_SUNSET_FEEDBACK_BUTTON: 'Contact us',
    COACHES_SUNSET_FEEDBACK_HEADLINE: 'Need assistance?',
    COACHES_SUNSET_HEADLINE:
      'XING Coaches + Trainers has been discontinued. But XING is still more than happy to help:',
    COACHES_SUNSET_KNOWLEDGE_CARD_BUTTON: 'Edit profile now',
    COACHES_SUNSET_KNOWLEDGE_CARD_DESCRIPTION:
      'Add coaching as a skill to your XING profile to make it easy for people to find you and your expertise.',
    COACHES_SUNSET_KNOWLEDGE_CARD_HEADLINE: 'Looking to attract new coachees?',
  },
};
